<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>搜索</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="search-box">
      <el-row>
        <el-col :span="20">
          <el-input
            v-model="keyword"
            class="condition-search"
            prefix-icon="el-icon-search"
            placeholder="请输入内容"
              @keyup.enter.native="doSearch"
          />
        </el-col>
        <el-col :span="4">
          <el-button 
            class="college-btn" 
            type="primary" 
            round 
            @click="doSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tabs">
      <span
        v-for="(item,i) in tabs"
        :key="i"
        :class="{tab:true, act:act===i}"
        @click="tabChange(i)"
      >{{ item.name }}</span>
    </div>
    <div class="result">
      <div v-if="act===0">
        <collegeList 
          v-for="(item,i) in list" 
          :key="i" 
          :item="item" />
      </div>
      <div v-if="act===1">
        <p 
          v-for="(item,i) in list" 
          :key="i" 
          class="spec">
          {{ item.name }}
          <router-link
            :to="{path:'/specialtyInfo',name:'specialtyInfo',query:{sid:item.specialty_code}}"
            class="detail"
          >查看详情</router-link>
        </p>

       
      </div>
      <div v-if="act===2">
        <el-row v-if="list.length>0">
          <el-col 
            :offset="5" 
            :span="14">
            <router-link
              v-for="(item,i) in list"
              :to="{patch:'/detail',name:'detail',query:{id:item.id}}"
              :key="i"
              class="items"
            >
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.brief }}</div>
              <!-- <span class="time">{{ item.create_time.split(" ")[0] }}</span> -->
            </router-link>
          </el-col>
        </el-row>
        <h2 
          v-else 
          class="text-c">暂无相关信息！</h2>
      </div>
      <div v-if="act===3">
        <el-row v-if="list.length>0">
          <el-col 
            :offset="5" 
            :span="14">
            <router-link
              v-for="(item,i) in list"
              :to="{path:'solution',name:'solution',query:{nid:item.id,cid:item.school_id}}"
              :key="i"
              target="_blank"
              class="items"
            >
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.desc }}</div>
              <!-- <span class="time">{{ item.add_time.split(" ")[0] }}</span> -->
            </router-link>
          </el-col>
        </el-row>
        <h2 
          v-else 
          class="text-c">暂无相关信息！</h2>
      </div>
    </div>
  </div>
</template>

<script>
import collegeList from "@/components/CollegeList";
export default {
  name: "Search",
  components: {
    collegeList
  },
  data() {
    return {
      keyword: "",
      act: 0,
      tabs: [
        { name: "找大学", url: "school/getSchoolList" },
        { name: "查专业", url: "specialty/searchSpecialtyName" },
        { name: "搜文章", url: "news/getData" },
        { name: "招生简章", url: "school_news/getData" }
      ],
      url: "school/getSchoolList",
      list: []
    };
  },
  watch: {
     '$route'() {
        this.keyword = this.$route.query.kwd;
         this.tabChange(0);
      }
  },
  mounted() {
    this.keyword = this.$route.query.kwd;
    let ttt = this.$route.query.type;
    if (!ttt) {
      ttt = 0;
    }
    let i = Number(ttt);
    this.tabChange(i);
  },
  methods: {
    tabChange(i) {
      this.act = i;
      this.url = this.tabs[i].url;
      this.doSearch();
    },
    doSearch() {
      if (this.keyword) {
        this.$fecth
          .post(this.url, {
            name: this.keyword
          })
          .then(res => {
            if (this.act === 2 || this.act === 3) {
              this.list = res.lists;
            } else {
              this.list = res.lists;
            }
          });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.page {
  .search-box {
    margin: 20px 0;

    .college-btn {
      padding: 15px 30px;
      font-size: 18px;
      display: block;
      margin: auto;
      border-radius: 60px;
    }
  }
  .tabs {
    background-color: #f2f2f2;

    .tab {
      display: inline-block;
      font-size: 22px;
      width: 20%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      transition: all 0.3s;

      &:hover {
        color: #404fdc;
      }

      &.act {
        background-color: #404fdc;
        color: #ffffff;
      }
    }
  }
  .result {
    padding: 20px;
  }
  .spec {
    line-height: 40px;
    padding: 10px 0;
    margin: 10px 0;
    font-size: 20px;
    .detail {
      float: right;
      background-color: #0000ff;
      color: #ffffff;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      width: 100px;
      text-align: center;
    }
  }

  .items {
    position: relative;
    height: 106px;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px dotted #999;
    box-sizing: border-box;
    margin-bottom: 15px;

    .title {
      font-size: 18px;
      color: #555;
      padding: 3px 0 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .desc {
      font-size: 14px;
      color: #818181;
      padding-right: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .time {
      font-size: 12px;
      color: #999;
      position: absolute;
      right: 0;
      bottom: 28px;
    }
  }
}
</style>